import { makeAutoObservable } from "mobx";
import extendDefaultHeaders from "utils/extendDefaultHeaders";
import handleApiPath from "utils/handleApiPath";
import { RootStore } from "./RootStore";
import { toast } from "react-hot-toast";

interface CreateSessionError {
  error_id: string;
  error_message: string;
  error_fields: Array<{
    code: string;
    name: string;
    message: string;
  }>;
  error_code: string;
  status_code?: number;
}

interface Filters {
  ageID?: string;
  categoryID?: string[];
  genderID?: string;
  groupID?: string;
  marketPlaceID?: number;
  brandId?: string;
  characterID?: string;
  producingCountryID?: string;
  pageNumber?: number;
  pageCount?: number;
  pageSize?: number;
  status?: number;
  priceMin?: number;
  priceMax?: number;
  searchText?: string;
  sortingOrder?: number;
  isFavorite?: boolean;
  prices?: any[];
  mpWarehouseID?: number;
  notAddedToMarketPlace?: boolean;
  propertyValueIDs?: string[];
}

interface CollationFilters {
  pageNumber?: number;
  pageCount?: number;
  pageSize?: number;
  searchText?: string;
}

interface UpdateInfoFilters {
  pageNumber?: number;
  pageCount?: number;
  pageSize?: number;
  searchText?: string;
  sortingOrder?: number;
}

export interface Product {
  age: string;
  ageID: string;
  amount: number;
  barcode: string;
  brand: string;
  brandID: string;
  category: string;
  categoryID: string;
  groupID: string;
  group: string;
  character: string | null;
  characterID: string | null;
  gender: string;
  genderID: string;
  status: string;
  minPrice: string | number;
  id: string;
  images: Array<{
    imageID: string;
    imageUri: string;
    userID: string;
  }>;
  packingDepthCm: number;
  packingDepthMm: number | null;
  packingLengthCm: number | null;
  packingLengthMm: number | null;
  packingWidthCm: number | null;
  packingWidthMm: number | null;
  packingPrice: number;
  producingCountry: string;
  producingCountryID: string;
  materialID: number;
  material: string;
  productColorID: number;
  productColor: string;
  stockPrice: number;
  title: string;
  description: string;
  vendorCode: string;
  weightGr: number | null;
  weightKg: number | null;
  isFavorite: boolean;
  additionalParameter: string;
  ozonProductID: number;
  wildberriesProductID: number;
  yandexProductID: number;
  wildberriesLink: string;
  yandexMarketLink: string;
  ozonLink: string;
  productPrices: {
    price: 21680;
    changeDate: string;
  }[];
  childParentID: string;
  childrenParentGroup: string;
  parentGroupID: string;
  parentGroup: string;
  commercialType: string;
  carType: string;
  targetAudience: string;
  mainSupplier: string;
  isCollection: boolean;
  mpWarehouseID: number;
  nmID: number | null;
}

export interface NewProduct {
  stockPrice: number;
  amount: number;
  stockAmount: number;
  isAddedToOzon: boolean;
  isAddedToWildberries: boolean;
  isAddedToYandexMarket: boolean;
  image: string;
  isFavorite: boolean;
  title: string;
  id: string;
}

export interface ProductCharacteristicI {
  item1: string;
  item2: string | number;
}

export interface PriceHistory {
  productID: string;
  productTitle: string;
  sellerVendorCode: string;
  lastChangeDate: string;
  articul: string;
  priceHistory: {
    price: number;
    changeDate: string;
  }[];
}

interface Collation {
  productID: string;
  productTitle: string;
  supplierVendorCode: string;
  ozonLink: string;
  wildberriesLink: string;
  yandexMarketLink: string;
}

interface Categories {
  value: string;
  label: string;
  groups: number;
}

export interface WarehouseFilterI {
  id: number;
  name: string;
}
interface UpdateInfo {
  productID: string;
  productTitle: string;
  userID: string;
  marketplaceID: number;
  marketplaceName: string;
  amount: number;
  updateDate: string;
  fileName: string;
  vendorCode: string;
  previousAmount: 60;
  previousDate: string;
  currentAmount: 60;
  currentDate: string;
  updateSource: string;
}

interface Video {
  categoryName: string;
  id: number;
  videos: {
    originalName: string;
    nameInBlob: string;
    roomsellerCategoriesID: number;
    id: number;
  }[];
}

export interface CategoriesTreeI {
  children: CategoriesTreeI[];
  GroupID: string;
  GroupTitle: string;
  PackingPrice: number;
}

class WarehouseStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  favoriteCount = 0;

  isLoading = false;

  products: {
    pageNumber: number;
    pageSize: number;
    products: {
      id: string;
      stockPrice: number;
      title: string;
      image: string;
      isAddedToOzon: boolean;
      isAddedToWildberries: boolean;
      isAddedToYandexMarket: boolean;
      isFavorite: boolean;
      amount: number;
      stockAmount: number;
    }[];
    pageCount: number;
    propertyValues: {
      [key: string]: { id: string; title: string; isSelected: boolean }[];
    };
  } | null = null;

  newProducts: NewProduct[] | null = null;

  product: Product | null = null;

  updateInfo: {
    pageNumber: number;
    pageSize: number;
    updateHistories: UpdateInfo[];
    pageCount: number;
  } | null = null;

  productCharacteristics: ProductCharacteristicI[] | null = null;

  productImages: Array<{
    imageID: string;
    imageUri: string;
    userID: string;
  }> | null = null;

  ages: { value: string; label: string }[] = [];

  brands: { value: string; label: string }[] = [];

  categories: Categories[] = [];

  groups: Categories[] = [];

  characters: { value: string; label: string }[] = [];

  statuses: { value: string; label: string }[] = [];

  genders: { value: string; label: string }[] = [];

  orders: { value: string; label: string }[] = [];

  countries: { value: string; label: string }[] = [];

  errorData: CreateSessionError | null = null;

  minPrice: number | null = null;

  maxPrice: number | null = null;

  searchTextEmpty = false;

  priceHistories: PriceHistory[] | null = null;

  collations: {
    pageNumber: number;
    pageSize: number;
    productCollations: Collation[];
    pageCount: number;
  } | null = null;

  videos: Video[] | null = null;

  categoriesTree: CategoriesTreeI[] | null = null;

  csvText: string | null = null;

  downloadProductsList: string | null = null;

  filters: { name: string; value: any; label: string }[] = [
    { name: "pageSize", value: "20", label: "20" },
    {
      name: "pageNumber",
      value: "1",
      label: "1",
    },
  ];

  setFiltersByFilter = (
    setFunction: (data: any) => void,
    data: { title: string; id: string }[]
  ) => {
    setFunction([
      ...data.map((item: FilterItem) => ({
        value: item.id,
        label: item.title,
      })),
    ]);
  };

  setFilters = (value: { name: string; value: any; label: string }[]): void => {
    this.filters = value;
  };

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setCSVText = (text: string): void => {
    this.csvText = text;
  };

  setFavoriteCount = (value: number): void => {
    this.favoriteCount = value;
  };

  setErrorData = (error: CreateSessionError | null): void => {
    this.errorData = error;
  };

  setProduct = (data: any): void => {
    this.product = data;
  };

  setUpdateInfo = (data: any): void => {
    this.updateInfo = data;
  };

  setProductCharacteristics = (data: ProductCharacteristicI[]): void => {
    this.productCharacteristics = data;
  };

  setData = (data: any): void => {
    this.products = data;
  };

  setNewProducts = (data: NewProduct[]): void => {
    this.newProducts = data;
  };

  setPriceHistories = (data: any): void => {
    this.priceHistories = data;
  };

  setCollations = (data: any): void => {
    this.collations = data;
  };

  setAges = (data: { value: string; label: string }[]): void => {
    this.ages = data;
  };

  setBrands = (data: { value: string; label: string }[]): void => {
    this.brands = data;
  };

  setCategories = (data: Categories[]): void => {
    this.categories = data;
  };

  setGroups = (data: Categories[]): void => {
    this.groups = data;
  };

  setCharacters = (data: { value: string; label: string }[]): void => {
    this.characters = data;
  };

  setGenders = (data: { value: string; label: string }[]): void => {
    this.genders = data;
  };

  setOrders = (data: { value: string; label: string }[]): void => {
    this.orders = data;
  };

  setCountries = (data: { value: string; label: string }[]): void => {
    this.countries = data;
  };

  setSearchTextEmpty = (data: boolean): void => {
    this.searchTextEmpty = data;
  };

  setPrices = (min: number, max: number) => {
    this.minPrice = min;
    this.maxPrice = max;
  };

  setVideos = (videos: Video[]) => {
    this.videos = videos;
  };

  setCategoriesTree = (tree: CategoriesTreeI[]): void => {
    this.categoriesTree = tree;
  };

  setDownloadProductsList = (url: string): void => {
    this.downloadProductsList = url;
  };

  getProduct = async (id: string): Promise<Product | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/GetProduct/${id}`,
        requestOptions
      );

      const data = await response.json();
      if (response.ok) {
        this.setProduct(data);
        return data;
      }

      return data;
    } catch (error) {
      return null;
    }
  };

  getProductCharacteristics = async (
    id: string,
    mpWarehouseID: number
  ): Promise<ProductCharacteristicI[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/ProductCharacteristics/?productID=${id}&mpWarehouseID=${mpWarehouseID}`,
        requestOptions
      );

      const data = await response.json();

      if (response.ok) {
        this.setProductCharacteristics(data);
        return data;
      } else {
        return null;
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getProducts = async ({
    ageID,
    groupID,
    genderID,
    brandId,
    characterID,
    status,
    marketPlaceID,
    producingCountryID,
    searchText,
    pageNumber,
    pageSize,
    priceMin,
    priceMax,
    sortingOrder,
    isFavorite,
    mpWarehouseID,
    notAddedToMarketPlace,
    propertyValueIDs,
  }: Filters): Promise<Item[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        ageID,
        groupID,
        genderID,
        brandId,
        characterID,
        status,
        marketPlaceID,
        producingCountryID,
        searchText,
        pageNumber,
        pageSize,
        priceMin,
        priceMax,
        sortingOrder,
        isFavorite,
        mpWarehouseID,
        notAddedToMarketPlace,
        propertyValueIDs,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(`${endpoint}/GetProducts`, requestOptions);
      const data = await response.json();

      if (response.ok) {
        this.setData(data);
        this.setPrices(data.minPrice, data.maxPrice);
        this.setGroups([]);

        data.groups.map((item: { id: string; title: string; groups: any }) => {
          this.setGroups([
            ...this.groups,
            {
              value: item.id,
              label: item.title,
              groups: 0,
            },
          ]);
          if (item.groups?.length) {
            item.groups.map(
              (item2: { id: string; title: string; groups: any }) => {
                this.setGroups([
                  ...this.groups,
                  {
                    value: item2.id,
                    label: item2.title,
                    groups: 1,
                  },
                ]);
                if (item2.groups?.length) {
                  item2.groups.map(
                    (item3: { id: string; title: string; groups: any }) => {
                      this.setGroups([
                        ...this.groups,
                        { value: item3.id, label: item3.title, groups: 2 },
                      ]);
                    }
                  );
                }
              }
            );
          }
        });
      }
      if (response.status === 404) {
        this.setData(null);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getUserReport = async ({
    groupID,
    marketPlaceID,
    searchText,
    pageNumber,
    pageSize,
    priceMin,
    priceMax,
    sortingOrder,
    isFavorite,
    mpWarehouseID,
    notAddedToMarketPlace,
    propertyValueIDs,
  }: Filters): Promise<boolean> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        groupID,
        searchText,
        pageNumber,
        pageSize,
        priceMin,
        priceMax,
        sortingOrder,
        isFavorite,
        mpWarehouseID,
        notAddedToMarketPlace,
        propertyValueIDs,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(`${endpoint}/GetUserReport`, requestOptions);

      if (response.ok) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  getGroups = async (): Promise<Categories[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(`${endpoint}/GetGroups`, requestOptions);
      const data = await response.json();
      if (response.ok) {
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getOrders = async (): Promise<FilterItem[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/GetProductSortingOrder`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setOrders([
          ...data.map((item: FilterItem) => ({
            value: item.id.toString(),
            label: item.title,
          })),
        ]);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  updateProductFavorite = async (productID: string): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        productID: productID,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/UpdateProductFavorite`,
        requestOptions
      );

      if (response.ok) {
        return true;
      }
      if (response.status === 404) {
        this.setData(null);
        return false;
      }
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  syncProduct = async (warehouseID: number): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };
    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/ProductSynchronize?warehouseID=${warehouseID}`,
        requestOptions
      );

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getFavoriteCount = async (): Promise<number | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/UserFavoritesCount`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setFavoriteCount(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getPriceHistories = async ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }): Promise<Item[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        startDate,
        endDate,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/GetProductPriceHistory`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setPriceHistories(data);
      }
      if (response.status === 404) {
        this.setPriceHistories(null);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getPriceHistoriesCSV = async ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        startDate,
        endDate,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/ProductPriceHistoryToCSV`,
        requestOptions
      );
      const data = await response.blob();
      if (response.ok) {
        this.setCSVText(window.URL.createObjectURL(data));
      }
      this.setIsLoading(false);

      return response;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getCollations = async ({
    pageNumber,
    pageSize,
    searchText,
  }: CollationFilters): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);
    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        pageNumber,
        pageSize,
        searchText,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/GetProductCollations`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setCollations(data);
      }
      if (response.status === 404) {
        this.setCollations(null);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getChildrenCategoryIDs = async (
    parentGroupID: string,
    childrenParentGroupID: string
  ): Promise<string[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);
    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: parentGroupID
        ? JSON.stringify({
            parentGroupID: parentGroupID,
          })
        : JSON.stringify({
            childrenParentGroupID: childrenParentGroupID,
          }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/GetChildrenCategoryIDs`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        return data;
      }
      if (response.status === 404) {
        this.setCollations(null);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  collateProduct = async (
    productId: string,
    marketplaceId: number,
    vendorCode: string
  ): Promise<string[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);
    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        productID: productId,
        marketPlaceID: marketplaceId,
        marketPlaceVendorCode: vendorCode,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/CollateProduct`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        return null;
      }
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  addVideosCategory = async (category: string): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/AddCategory?CategoryName=${category}`,
        requestOptions
      );

      if (response.ok) {
        return true;
      }
      if (response.status === 404) {
        this.setData(null);
        return false;
      }
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getVideos = async (): Promise<Video[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(`${endpoint}/GetAllVideos`, requestOptions);

      const data = await response.json();

      if (response.ok) {
        this.setVideos(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getVideo = async (videoId: number): Promise<any> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/GetVideo?VideoID=${videoId}`,
        requestOptions
      );

      const data = await response.text();

      if (response.ok) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  addVideo = async (file: any, categoryId: number) => {
    const { userStore } = this.rootStore;

    const formData = new FormData();
    formData.append("video", file);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
      body: formData,
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/AddVideo?CategoryId=${categoryId}`,
        requestOptions
      );
      if (response.ok) {
        toast.success("Видео загружено, обновите страницу");
      } else {
        toast.error("Произошла ошибка");
      }
    } catch (error) {
      toast.error("Произошла ошибка");
    }
  };

  deleteVideo = async (videoId: number) => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/DeleteVideo?VideoId=${videoId}`,
        requestOptions
      );
      if (response.ok) {
        toast.success("Видео удалено, обновите страницу");
      } else {
        toast.error("Произошла ошибка");
      }
    } catch (error) {
      toast.error("Произошла ошибка");
    }
  };

  deleteCategory = async (categoryId: number) => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/DeleteCategory?CategoryId=${categoryId}`,
        requestOptions
      );
      if (response.ok) {
        toast.success("Категория удалена");
        this.setVideos(
          this.videos ? this.videos.filter((i) => i.id !== categoryId) : []
        );
      } else {
        toast.error("Произошла ошибка");
      }
    } catch (error) {
      toast.error("Произошла ошибка");
    }
  };

  getNewProducts = async (): Promise<NewProduct[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/GetNewProducts`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setNewProducts(data);
      }
      if (response.status === 404) {
        this.setNewProducts([]);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  addImage = async (file: any, productID: string) => {
    const { userStore } = this.rootStore;

    const formData = new FormData();
    formData.append("image", file);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
      body: formData,
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/AddImage/${productID}`,
        requestOptions
      );
      if (response.ok) {
        toast.success("Фотография загружена, обновите страницу");
      } else {
        toast.error("Произошла ошибка");
      }
    } catch (error) {
      toast.error("Произошла ошибка");
    }
  };

  getUpdateInfo = async ({
    pageNumber,
    pageSize,
    searchText,
  }: UpdateInfoFilters): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);
    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        pageNumber,
        pageSize,
        searchText,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/GetProductUpdateInfo`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setUpdateInfo(data);
      }
      if (response.status === 404) {
        this.setUpdateInfo(null);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getCategoriesTree = async (): Promise<CategoriesTreeI[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/GetCategoriesTree`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setCategoriesTree(data.children);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  changeCategoriesTree = async (): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        children: this.categoriesTree,
        GroupID: "00000000-0000-0000-0000-000000000000",
        GroupTitle: "",
        PackingPrice: 0,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/SetCategoriesTree`,
        requestOptions
      );

      if (response.ok) {
        return true;
      } else {
        toast.error("Произошла ошибка");
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  /*AskQuestionSection*/
  addProductChat = async (
    productID: string,
    productChatQuestion: string
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        productID: productID,
        productChatQuestion: productChatQuestion,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/AddProductChat`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Вопрос отправлен");
        return true;
      }
      if (response.status === 404) {
        this.setData(null);
        toast.error("Произошла ошибка");
        return false;
      }
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getProductChat = async (ProductID: string): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);
    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/GetProductChat?ProductID=${ProductID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setUpdateInfo(data);
      }
      if (response.status === 404) {
        this.setUpdateInfo(null);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  deleteProductChat = async (productChatId: number): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/DeleteProductChat?productChatId=${productChatId}`,
        requestOptions
      );
      //if (response.ok) {
      //  toast.success("Видео удалено, обновите страницу");
      //} else {
      //  toast.error("Произошла ошибка");
      //}
      if (response.ok) {
        console.log(productChatId);
        toast.error("Вопрос удален ");
        return true;
      }
      return false;
    } catch (error) {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  getProductChatQA = async (): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);
    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(`${endpoint}/ProductChatQA`, requestOptions);
      const data = await response.json();

      if (response.ok) {
        this.setUpdateInfo(data);
      }
      if (response.status === 404) {
        this.setUpdateInfo(null);
      }
      this.setIsLoading(false);
      console.log(data);
      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  AnswerProductChat = async (
    productChatId: number,
    productChatAnswer: string
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
    const response = await fetch(
      `${endpoint}/AnswerProductChat?productChatId=${productChatId}&productChatAnswer=${productChatAnswer}`,
      requestOptions
    );

    if (response.ok) {
      toast.success("Ответ отправлен");
      return true;
    } else {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  /*filters*/
  getFilters = async (warehouseID: any): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);
    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(
        `${endpoint}/GetFilters?mpWarehouseID=${warehouseID || 1}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setUpdateInfo(data);
      }
      this.setUpdateInfo(null);
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  addFilters = async (
    mpWarehouseID: number | undefined,
    propertiesIDs: any
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        mpWarehouseID: mpWarehouseID,
        propertiesIDs: propertiesIDs,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WAREHOUSE");
      const response = await fetch(`${endpoint}/SetFilters`, requestOptions);

      if (response.ok) {
        toast.success("Фильтры изменены");
        return true;
      }
      if (response.status === 404) {
        //  this.setData(null);
        toast.error("Произошла ошибка");
        return false;
      }
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };
}

export default WarehouseStore;
