import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Cart.module.scss";
import { observer } from "mobx-react-lite";
import SvgIcon from "components/SvgIcon";
import { ArrowDownSvg, ArrowUpSvg } from "components/SvgIcons";
import { HiDocumentDownload } from "react-icons/hi";
import moment from "moment";

import { toast } from "react-hot-toast";
import YandexLogo from "assets/icons/yandex.png";
import OzonLogo from "assets/icons/ozon.png";
import WildberriesLogo from "assets/icons/wildberries.png";
import { ProfileInfo } from "../../../stores/UserStore";
import { Cart } from "../../../stores/OrderStore";
import { RootStoreContext } from "../../../stores/RootStore";
import { MdOutlineCancel } from "react-icons/md";
import Button from "../../../components/Button";
import { useReactToPrint } from "react-to-print";
import PrintContent from "./printContent";
import { BeatLoader } from "react-spinners";
import Checkbox from "components/Checkbox";

interface Props {
  loading: boolean;
  info: ProfileInfo | null;
  sortingOrder: string | null;
  handleOrderBy: (sortingOrder: number) => void;
  carts: {
    pageNumber: number;
    pageSize: number;
    orders: Cart[];
    pageCount: number;
  } | null;
  marketplace: string | null;
}

const CartTable: React.FC<Props> = observer(
  ({ loading, info, sortingOrder, handleOrderBy, carts, marketplace }) => {
    const {
      orderStore: {
        getOrderLabel,
        createWbDelivery,
        getWbOrderLabel,
        setCarts,
        setOrderProcess,
      },
      yandexStore: { yandexCancelOrder },
      wildberriesStore: { wildberriesCancelOrder },
      ozonStore: { ozonCancelOrder },
    } = useContext(RootStoreContext);

    const [labelLoading, setLabelLoading] = useState<boolean>(false);
    const [cancelLoad, setCancelLoad] = useState<boolean>(false);
    const [createLoad, setCreateLoad] = useState<number>();

    const [printItems, setPrintItems] = useState<
      {
        marketPlaceId: number;
        itemId: number;
        file: string;
      }[]
    >([]);

    const printRef = useRef(null);

    useEffect(() => {
      const pages = document.querySelectorAll(".printContent")[0];

      const style = document.createElement("style");

      style.innerHTML = `
        @page {
            size: ${
              Number(marketplace) === 3
                ? "400px 300px"
                : Number(marketplace) === 2
                ? "80.5mm 126mm"
                : Number(marketplace) === 1
                ? "74.5mm 121mm"
                : ""
            };
        }
    `;

      pages.appendChild(style);
    }, [marketplace]);

    useEffect(() => {
      if (createLoad) {
        createWbDelivery(createLoad).then(() => {
          setCreateLoad(0);
        });
      }
    }, [createLoad, setCreateLoad, createWbDelivery]);

    useEffect(() => {
      setPrintItems([]);
    }, [marketplace]);

    const generatePDF = useReactToPrint({
      content: () => printRef.current,
    });
    const chooseAll = (carts: Cart[]) => {
      carts.map((cart) => {
        if (printItems.filter((i) => i.itemId === cart.id).length === 0) {
          setLabelLoading(true);
          if (cart.marketPlaceID === 3) {
            getWbOrderLabel(cart.id, "print").then((i) => {
              if (i) {
                setPrintItems((state) => [
                  ...state,
                  {
                    marketPlaceId: cart.marketPlaceID,
                    itemId: cart.id,
                    file: i,
                  },
                ]);
              } else {
                toast.error(
                  "Этикетка еще не готова. Это может занять примерно 24 часа с момента заказа."
                );
              }
              setLabelLoading(false);
            });
          } else {
            getOrderLabel(cart.id, "print").then((i) => {
              if (i !== "") {
                setPrintItems((state) => [
                  ...state,
                  {
                    marketPlaceId: cart.marketPlaceID,
                    itemId: cart.id,
                    file: i,
                  },
                ]);
              } else {
                toast.error(
                  "Этикетка еще не готова. Это может занять примерно 24 часа с момента заказа."
                );
              }
              setLabelLoading(false);
            });
          }
        }
      });
    };

    return (
      <div>
        {loading ? (
          <div style={{ width: "fit-content", margin: "auto" }}>
            <BeatLoader color={"#f49100"} loading={loading} size={50} />
          </div>
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                {/*<th>ID</th>*/}
                {info &&
                  (info?.roles.includes("SuperAdmin") ||
                    info?.roles.includes("WarehouseOwner")) && (
                    <th>
                      <div className={styles.theadTh}>
                        Продавец
                        <div>
                          <span>
                            <SvgIcon
                              size={0.8}
                              cursor={true}
                              component={<ArrowUpSvg />}
                              className={
                                Number(sortingOrder) === 0
                                  ? styles.activeArrow
                                  : ""
                              }
                              onClick={() => handleOrderBy(0)}
                            />
                          </span>
                          <span>
                            <SvgIcon
                              size={0.8}
                              cursor={true}
                              component={<ArrowDownSvg />}
                              className={
                                Number(sortingOrder) === 1
                                  ? styles.activeArrow
                                  : ""
                              }
                              onClick={() => handleOrderBy(1)}
                            />
                          </span>
                        </div>
                      </div>
                    </th>
                  )}
                <th>
                  <div className={styles.theadTh}>
                    Наименование товар (1С)
                    <div>
                      <span>
                        <SvgIcon
                          size={0.8}
                          cursor={true}
                          component={<ArrowUpSvg />}
                          className={
                            Number(sortingOrder) === 4 ? styles.activeArrow : ""
                          }
                          onClick={() => handleOrderBy(4)}
                        />
                      </span>
                      <span>
                        <SvgIcon
                          size={0.8}
                          cursor={true}
                          component={<ArrowDownSvg />}
                          className={
                            Number(sortingOrder) === 5 ? styles.activeArrow : ""
                          }
                          onClick={() => handleOrderBy(5)}
                        />
                      </span>
                    </div>
                  </div>
                </th>
                <th>Склад</th>
                <th>Кол.</th>
                <th>Цена РС</th>
                <th>Сумма РС</th>
                <th>Цена МП</th>
                <th>Сумма МП</th>
                <th>
                  <div className={styles.theadTh}>
                    МП
                    <div>
                      <span>
                        <SvgIcon
                          size={0.8}
                          cursor={true}
                          component={<ArrowUpSvg />}
                          className={
                            Number(sortingOrder) === 2 ? styles.activeArrow : ""
                          }
                          onClick={() => handleOrderBy(2)}
                        />
                      </span>
                      <span>
                        <SvgIcon
                          size={0.8}
                          cursor={true}
                          component={<ArrowDownSvg />}
                          className={
                            Number(sortingOrder) === 3 ? styles.activeArrow : ""
                          }
                          onClick={() => handleOrderBy(3)}
                        />
                      </span>
                    </div>
                  </div>
                </th>
                <th>Номер заказа на МП</th>
                <th>Код поставщика</th>
                <th>
                  <div className={styles.theadTh}>
                    Дата заказа
                    <div>
                      <span>
                        <SvgIcon
                          size={0.8}
                          cursor={true}
                          component={<ArrowUpSvg />}
                          className={
                            Number(sortingOrder) === 6 ? styles.activeArrow : ""
                          }
                          onClick={() => handleOrderBy(6)}
                        />
                      </span>
                      <span>
                        <SvgIcon
                          size={0.8}
                          cursor={true}
                          component={<ArrowDownSvg />}
                          className={
                            Number(sortingOrder) === 7 ? styles.activeArrow : ""
                          }
                          onClick={() => handleOrderBy(7)}
                        />
                      </span>
                    </div>
                  </div>
                </th>
                <th>
                  <div className={styles.theadTh}>
                    Дата отгрузки
                    <div>
                      <span>
                        <SvgIcon
                          size={0.8}
                          cursor={true}
                          component={<ArrowUpSvg />}
                          className={
                            Number(sortingOrder) === 8 ? styles.activeArrow : ""
                          }
                          onClick={() => handleOrderBy(8)}
                        />
                      </span>
                      <span>
                        <SvgIcon
                          size={0.8}
                          cursor={true}
                          component={<ArrowDownSvg />}
                          className={
                            Number(sortingOrder) === 9 ? styles.activeArrow : ""
                          }
                          onClick={() => handleOrderBy(9)}
                        />
                      </span>
                    </div>
                  </div>
                </th>
                <th>Статус</th>
                <th></th>
                <th>Этикетка</th>
                {marketplace ? (
                  <th>
                    <Button
                      className={styles.payTbButton}
                      disabled={printItems.length ? false : true}
                      onClick={generatePDF}
                    >
                      {`Распечатать (${printItems.length})`}
                    </Button>
                    {carts ? (
                      <Button
                        className={
                          labelLoading
                            ? styles.chooseAllTbButtonProgress
                            : styles.chooseAllTbButton
                        }
                        onClick={() => chooseAll(carts?.orders)}
                      >
                        Выбрать все
                      </Button>
                    ) : (
                      ""
                    )}
                  </th>
                ) : (
                  ""
                )}
                {info?.roles.includes("WarehouseOwner") ? <th>Torg 12</th> : ""}
              </tr>
            </thead>
            <tbody>
              {carts &&
                carts.orders.map((item, index) => (
                  <tr key={index}>
                    {info &&
                      (info?.roles.includes("SuperAdmin") ||
                        info?.roles.includes("WarehouseOwner")) && (
                        <td>{item.seller}</td>
                      )}
                    <td>
                      <a
                        href={`/warehouse/product/${item.productID}`}
                        target="_blank"
                        className={styles.cardTitle}
                      >
                        {item.productTitle}
                      </a>
                    </td>
                    <td>{item.warehouseName}</td>
                    <td>{item.quantity}</td>
                    <td>{parseFloat(Number(item.stockPrice).toFixed(2))} ₽</td>
                    <td>
                      {parseFloat(Number(item.totalStockPrice).toFixed(2))} ₽
                    </td>
                    <td>{parseFloat(Number(item.price).toFixed(2))} ₽</td>
                    <td>{parseFloat(Number(item.totalPrice).toFixed(2))} ₽</td>
                    <td className={styles.MPLogos}>
                      {item.marketPlaceName === "YandexMarket" ? (
                        <img src={YandexLogo} alt="ozon logo" />
                      ) : item.marketPlaceName === "Ozon" ? (
                        <img src={OzonLogo} alt="ozon logo" />
                      ) : (
                        <img src={WildberriesLogo} alt="ozon logo" />
                      )}
                    </td>
                    <td>{item.postingNumber}</td>
                    <td>{item.supplierVendorCode}</td>
                    <td>{moment(item.orderDate).format("DD.MM.YYYY")}</td>
                    <td>
                      {item.shipmentDate
                        ? moment(item.shipmentDate).format("DD.MM.YYYY")
                        : ""}
                    </td>
                    <td>{item.status}</td>
                    <td>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Отменить</Tooltip>}
                      >
                        <div>
                          <MdOutlineCancel
                            className={
                              cancelLoad
                                ? styles.cancelButtonLoad
                                : styles.cancelButton
                            }
                            size={32}
                            color="#f33"
                            onClick={() => {
                              setCancelLoad(true);
                              if (item.marketPlaceID === 1) {
                                ozonCancelOrder(item.id).then(() => {
                                  setCancelLoad(false);
                                });
                              } else if (item.marketPlaceID === 2) {
                                yandexCancelOrder(item.id).then(() => {
                                  setCancelLoad(false);
                                });
                              } else {
                                wildberriesCancelOrder(item.id).then(() => {
                                  setCancelLoad(false);
                                });
                              }
                            }}
                          />
                        </div>
                      </OverlayTrigger>
                    </td>

                    <td className={styles.packageLabel}>
                      <div
                        style={{
                          cursor: labelLoading ? "progress" : "pointer",
                        }}
                        onClick={() => {
                          setLabelLoading(true);

                          if (item.marketPlaceID === 3) {
                            getWbOrderLabel(item.id, "download").then((i) => {
                              if (!i) {
                                toast.error(
                                  "Этикетка еще не готова. Это может занять примерно 24 часа с момента заказа."
                                );
                              }
                              setLabelLoading(false);
                            });
                          } else {
                            getOrderLabel(item.id, "download").then((i) => {
                              if (i === "") {
                                toast.error(
                                  "Этикетка еще не готова. Это может занять примерно 24 часа с момента заказа."
                                );
                              }
                              setLabelLoading(false);
                            });
                          }
                        }}
                        className={styles.packageLabelA}
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Скачать</Tooltip>}
                        >
                          <div>
                            <HiDocumentDownload size={38} />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </td>

                    {marketplace ? (
                      <td className={styles.payCheckbox}>
                        <input
                          type="checkbox"
                          style={{
                            cursor: labelLoading ? "progress" : "pointer",
                          }}
                          onChange={() => {
                            if (
                              printItems.filter((e) => e.itemId === item.id)
                                .length === 1
                            ) {
                              setPrintItems(
                                printItems.filter((i) => i.itemId !== item.id)
                              );
                            } else {
                              setLabelLoading(true);
                              if (item.marketPlaceID === 3) {
                                getWbOrderLabel(item.id, "print").then((i) => {
                                  if (i) {
                                    setPrintItems((state) => [
                                      ...state,
                                      {
                                        marketPlaceId: item.marketPlaceID,
                                        itemId: item.id,
                                        file: i,
                                      },
                                    ]);
                                  } else {
                                    toast.error(
                                      "Этикетка еще не готова. Это может занять примерно 24 часа с момента заказа."
                                    );
                                  }
                                  setLabelLoading(false);
                                });
                              } else {
                                getOrderLabel(item.id, "print").then((i) => {
                                  if (i !== "") {
                                    setPrintItems((state) => [
                                      ...state,
                                      {
                                        marketPlaceId: item.marketPlaceID,
                                        itemId: item.id,
                                        file: i,
                                      },
                                    ]);
                                  } else {
                                    toast.error(
                                      "Этикетка еще не готова. Это может занять примерно 24 часа с момента заказа."
                                    );
                                  }
                                  setLabelLoading(false);
                                });
                              }
                            }
                          }}
                          checked={
                            printItems.filter((e) => e.itemId === item.id)
                              .length === 1
                          }
                        />
                      </td>
                    ) : (
                      ""
                    )}
                    {info?.roles.includes("WarehouseOwner") ? (
                      <td style={{ textAlign: "center" }}>
                        <Checkbox
                          checked={
                            item.processStage === 1 || item.processStage === 4
                          }
                          onChange={() => {
                            setOrderProcess(
                              item.id,
                              item.processStage === 1 ? false : true
                            ).then((bool) => {
                              if (bool) {
                                setCarts({
                                  ...carts,
                                  orders: carts.orders.map((i) =>
                                    i.id === item.id
                                      ? {
                                          ...i,
                                          processStage:
                                            item.processStage === 1 ? 0 : 1,
                                        }
                                      : i
                                  ),
                                });
                              } else {
                                toast.error("Произошла ошибка");
                              }
                            });
                          }}
                          className={styles.normalCheckbox}
                          disabled={item.processStage === 4}
                        />
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
        <div style={{ display: "none" }}>
          <div ref={printRef} className="printContent">
            <PrintContent printItems={printItems} />
          </div>
        </div>
      </div>
    );
  }
);
export default CartTable;
