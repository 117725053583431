import { makeAutoObservable } from "mobx";
import extendDefaultHeaders from "utils/extendDefaultHeaders";
import handleApiPath from "utils/handleApiPath";
import { RootStore } from "./RootStore";
import { MarketPlaces } from "./MarketPlaceStore";
import { WarehouseFilterI } from "./WarehouseStore";
import toast from "react-hot-toast";

interface CreateSessionError {
  error_id: string;
  error_message: string;
  error_fields: Array<{
    code: string;
    name: string;
    message: string;
  }>;
  error_code: string;
  status_code?: number;
}

export interface Cart {
  address: string;
  id: number;
  marketPlaceID: number;
  marketPlaceName: string;
  orderDate: string;
  productID: string;
  productTitle: string;
  seller: string;
  sellerID: string;
  status: string;
  postingNumber: string;
  supplierVendorCode: string;
  shipmentDate: string | null;
  packageLabel: string;
  price: string;
  totalPrice: number;
  quantity: number;
  stockPrice: number;
  totalStockPrice: number;
  paymentStatus: string;
  warehouseName: string;
  processStage: number;
}

interface Filters {
  marketPlaceID?: number;
  mpWarehouseID?: number;
  pageNumber?: number;
  sortingOrder?: number;
  pageSize?: number;
  sellerID?: string;
  orderStatus?: number;
  searchText?: string;
  shipmentDateFrom?: string;
  shipmentDateTo?: string | null;
}

interface ReturnsFilters {
  pageNumber?: number;
  pageCount?: number;
  pageSize?: number;
  searchText?: string;
  sellerID?: string;
  marketPlaceID?: number;
  mpWarehouseID?: number;
}

export interface Seller {
  id: string;
  sellerName: string;
}

interface Returns {
  pageNumber: number;
  pageSize: number;
  pageCount: number;
  marketPlaces: MarketPlaces[];
  sellers: Seller[];
  warehouses: WarehouseFilterI[];
  returns: {
    fullName: string;
    marketplaceName: string;
    productTitle: string;
    vendoreCode: string;
    postingNumber: string;
    orderDate: string;
    receiveDate: string;
    returnDate: string;
    returnStatus: number;
    userID: string;
    warehouseName: string;
  }[];
}

interface Invoice {
  id: number;
  invoiceNumber: number;
  marketPlaceID: number;
  marketPlaceName: string;
  sellerID: string;
  seller: string;
  price: number;
  paymentStatus: number;
  signatureStatus: number;
  createDate: string;
  paymentDate: string | null;
}

interface PaymentBill {
  documentNm: number;
  payerID: string;
  payerFullName: string;
  recipientID: string;
  recipientFullName: string;
  description: string;
  payerSign: string;
  recipientSign: string;
  totalPrice: number;
  totalVat: number;
  billType: number;
}

export interface TorgTablePart {
  bruttoWeight: number;
  orderID: number;
  productAmount: number;
  productID: string;
  productPackingPrice: number;
  productPrice: number;
  productTitle: string;
  productVat: number;
  vendorCode: string;
}
interface Torg12 {
  buyer: string;
  buyerID: string;
  buyerSign: string | null;
  creationDate: string;
  seller: string;
  sellerID: string;
  sellerSign: string | null;
  torg12Nm: number;
  torg12TablePartModel: TorgTablePart[];
  totalPackingPrice: number;
  torg12SellerType: number;
  totalPrice: number;
  totalVat: number;
}

interface ObligationsByUsersI {
  item1: {
    item1: string;
    item2: string;
  };
  item2: {
    item1: number;
    item2: string;
    item3: {
      balanceSum: number;
      paidDate: string | null;
      paidSum: number;
      totalPriceWarehouse: number;
      totalPackWarehouse: number;
      totalByWarehouse: number;
    };
  }[];
  item3: {
    balanceSum: number;
    paidDate: string | null;
    paidSum: number;
    totalPriceWarehouse: number;
    totalPackWarehouse: number;
    totalByWarehouse: number;
  };
}
interface ObligationsByWarehousesI {
  item1: number;
  item2: string;
  item3: {
    totalPriceWarehouse: number;
    totalPackWarehouse: number;
    totalByWarehouse: number;
  };
}
class OrderStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  isLoading = false;

  carts: {
    pageNumber: number;
    pageSize: number;
    orders: Cart[];
    pageCount: number;
    dateFrom: string;
    dateTo: string;
    marketPlaces: MarketPlaces[];
    sellers: Seller[];
    warehouses: WarehouseFilterI[];
  } | null = null;

  returns: Returns | null = null;

  invoices: Invoice[] | null = null;

  paymentBills: PaymentBill[] | null = null;

  torg12List: Torg12[] | null = null;

  cart: {
    id: number;
    isAdmin: boolean;
    sellerID: string;
    seller: string;
    marketPlaceID: number;
    marketPlaceName: string;
    productID: string;
    productTitle: string;
    postingNumber: string;
    orderDate: string;
    address: string;
    status: string;
    city: string;
    region: string;
    deliveryType: string;
    warehouseName: string;
    shipmentDate: string;
    price: string;
    totalPrice: number;
    quantity: number;
  } | null = null;

  orderStatuses: Array<{
    id: number;
    title: string;
  }> | null = null;

  filters: { name: string; value: string; label: string }[] = [
    { name: "pageSize", value: "20", label: "20" },
  ];

  errorData: CreateSessionError | null = null;

  sellers: Seller[] = [];

  obligationsByUsers: ObligationsByUsersI[] | null = null;

  obligationsByWarehouses: ObligationsByWarehousesI[] | null = null;

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setOrderStatuses = (data: any) => {
    this.orderStatuses = data;
  };

  setSellers = (data: Seller[]): void => {
    this.sellers = data;
  };

  setObligationsByUsers = (data: ObligationsByUsersI[]): void => {
    this.obligationsByUsers = data;
  };

  setObligationsByWarehouses = (data: ObligationsByWarehousesI[]): void => {
    this.obligationsByWarehouses = data;
  };

  setErrorData = (error: CreateSessionError | null): void => {
    this.errorData = error;
  };

  setCarts = (data: any): void => {
    this.carts = data;
  };

  setReturns = (data: any): void => {
    this.returns = data;
  };

  setInvoices = (data: Invoice[] | null): void => {
    this.invoices = data;
  };

  setPaymentBills = (data: PaymentBill[]): void => {
    this.paymentBills = data;
  };

  setTorg12List = (data: Torg12[]): void => {
    this.torg12List = data;
  };

  setCart = (data: any): void => {
    this.cart = data;
  };

  getOrders = async ({
    marketPlaceID,
    sortingOrder,
    pageNumber,
    pageSize,
    sellerID,
    mpWarehouseID,
    orderStatus,
    searchText,
    shipmentDateFrom,
    shipmentDateTo,
  }: Filters): Promise<{
    pageNumber: number;
    pageSize: number;
    orders: Cart[];
    pageCount: number;
    dateFrom: string;
    dateTo: string;
    marketPlaces: MarketPlaces[];
    sellers: Seller[];
    warehouses: WarehouseFilterI[];
  } | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        sortingOrder,
        marketPlaceID,
        mpWarehouseID,
        pageNumber,
        pageSize,
        sellerID,
        orderStatus,
        searchText,
        shipmentDateFrom,
        shipmentDateTo,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(`${endpoint}/GetOrders`, requestOptions);
      const data = await response.json();

      if (response.ok) {
        this.setCarts(data);
      }
      if (response.status === 404) {
        this.setCarts(null);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getOrder = async (id: number): Promise<Item[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetOrder/${id}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setCart(data);
      }
      if (response.status === 404) {
        this.setCart(null);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getSellers = async (): Promise<Seller[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetSellersWithOrders`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setSellers(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getOrderStatuses = async (): Promise<any> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetOrderStatuses`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setOrderStatuses(data);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getOrderLabel = async (id: number, type: string): Promise<string> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetOrderLabels/${id}`,
        requestOptions
      );
      const blob = await response.blob();

      if (response.ok && blob) {
        if (type === "download") {
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = "Label.pdf";
          a.click();
        }
        this.setIsLoading(false);
        return window.URL.createObjectURL(blob);
      } else {
        this.setIsLoading(false);
        return "";
      }
    } catch (error) {
      this.setIsLoading(false);
      return "";
    }
  };

  getWbOrderLabel = async (
    id: number,
    type: string
  ): Promise<string | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetWbOrderLabels/${id}`,
        requestOptions
      );
      const blob = await response.blob();

      if (response.ok) {
        if (type === "download") {
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = "Label.png";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        this.setIsLoading(false);
        return window.URL.createObjectURL(blob);
      } else {
        this.setIsLoading(false);
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  createWbDelivery = async (id: number): Promise<string | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/CreateWbDelivery/${id}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        return data;
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  payInit = async (orders: string[]): Promise<any> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify(orders),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(`${endpoint}/PaymentInit`, requestOptions);
      const data = await response.text();

      return data;
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  getInvoices = async (): Promise<Invoice[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(`${endpoint}/GetInvoices`, requestOptions);
      const data = await response.json();

      if (response.ok) {
        this.setInvoices(data);
      }
      if (response.status === 404) {
        this.setInvoices(null);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getAssemblyTask = async (
    userId: string,
    MpID: number
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetAssemblyTask?UserID=${userId}&MpID=${MpID}`,
        requestOptions
      );
      const data = await response.blob();

      if (response.ok) {
        this.setIsLoading(false);
        return data;
      } else {
        this.setIsLoading(false);
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  getOrdersCount = async (
    userId: string,
    MpID: number
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetOrdersCount/?UserID=${userId}&MpID=${MpID}`,
        requestOptions
      );
      const data = await response.text();

      if (response.ok) {
        this.setIsLoading(false);
        return data;
      } else {
        this.setIsLoading(false);
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  getOrdersRevenue = async (
    userId: string,
    MpID: number,
    isSuperAdmin: boolean
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/${
          isSuperAdmin ? "OrdersWarehouseOwnerAmount" : "OrdersAmount"
        }?UserID=${userId}&MpID=${MpID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setIsLoading(false);
        return data;
      } else {
        this.setIsLoading(false);
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  getSalesDynamics = async (
    userId: string,
    MpID: number
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/SalesDynamics?UserID=${userId}&MpID=${MpID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setIsLoading(false);
        return data;
      } else {
        this.setIsLoading(false);
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  getSalesDynamicsByWarehouse = async (
    userId: string,
    MpWarehouseID: number,
    MpID: number
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/SalesDynamicsByWarehouses?UserID=${userId}&MpID=${MpID}&MpWarehouseID=${MpWarehouseID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setIsLoading(false);
        return data;
      } else {
        this.setIsLoading(false);
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  getTodayPriceChanges = async (userId: string): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/TodayPriceChanges?UserID=${userId}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setIsLoading(false);
        return data;
      } else {
        this.setIsLoading(false);
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  getReturned = async ({
    pageNumber,
    pageSize,
    sellerID,
    marketPlaceID,
    searchText,
    mpWarehouseID,
  }: ReturnsFilters): Promise<Returns | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        pageNumber,
        pageSize,
        sellerID,
        marketPlaceID,
        searchText,
        mpWarehouseID,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(`${endpoint}/GetReturned`, requestOptions);
      const data = await response.json();

      if (response.ok) {
        this.setReturns(data);
      } else {
        this.setReturns(null);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getObligationsByUsers = async (
    startDate: string,
    endDate: string
  ): Promise<ObligationsByUsersI[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetFinancialObligationsByUsers?startDate=${startDate}&endDate=${endDate}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setObligationsByUsers(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getObligationsByWarehouses = async (
    startDate: string,
    endDate: string
  ): Promise<ObligationsByWarehousesI[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetFinancialObligationsByWarehouses?startDate=${startDate}&endDate=${endDate}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setObligationsByWarehouses(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getPaymentBillsList = async (
    userID: string,
    startDate: string,
    endDate: string,
    role: string
  ): Promise<PaymentBill[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetPaymentBillsList?UserID=${userID}&StartDate=${startDate}&EndDate=${endDate}&Role=${role}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setPaymentBills(data);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getPaymentBill = async (paymentBillID: string): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetPaymentBill?PaymentBillID=${paymentBillID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
      } else {
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  signPaymentBill = async (
    userID: string,
    paymentBills: number[]
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify(paymentBills),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/SignPaymentBills?UserID=${userID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
      } else {
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getTorg12List = async (
    buyerID: string,
    sellerID: string,
    startDate: string,
    endDate: string
  ): Promise<Torg12[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetTorg12List?BuyerID=${buyerID}&SellerID=${sellerID}&StartDate=${startDate}&EndDate=${endDate}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setTorg12List(data);
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getTorg12 = async (torg12ID: string): Promise<Torg12 | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetTorg12?torg12ID=${torg12ID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
      } else {
      }
      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  signTorg12 = async (userID: string, torgs: number[]): Promise<boolean> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify(torgs),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/SignTorg12?UserID=${userID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        return true;
      }
      return false;
    } catch (error) {
      this.setIsLoading(false);

      return false;
    }
  };

  downloadTorg12 = async (torg12: number): Promise<string> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/DownloadTorg12?Torg12N=${torg12}`,
        requestOptions
      );
      const blob = await response.blob();

      if (response.ok && blob) {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "torg12.pdf";
        a.click();
        this.setIsLoading(false);
        return window.URL.createObjectURL(blob);
      } else {
        this.setIsLoading(false);
        return "";
      }
    } catch (error) {
      this.setIsLoading(false);
      return "";
    }
  };

  downloadBill = async (billN: number): Promise<string> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/DownloadBill?BillN=${billN}`,
        requestOptions
      );
      const blob = await response.blob();

      if (response.ok && blob) {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "Bill.pdf";
        a.click();
        this.setIsLoading(false);
        return window.URL.createObjectURL(blob);
      } else {
        this.setIsLoading(false);
        return "";
      }
    } catch (error) {
      this.setIsLoading(false);
      return "";
    }
  };

  getWarehouseShipmentActs = async (
    warehouseID: number,
    MpID: number
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/GetWarehouseShipmentActs?marketPlaceID=${MpID}&mpWarehouseID=${warehouseID}`,
        requestOptions
      );
      const data = await response.blob();

      if (response.ok) {
        this.setIsLoading(false);
        return data;
      } else {
        this.setIsLoading(false);
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  approveWarehouseShipmentActs = async (
    warehouseID: number,
    MpID: number
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/ApproveWarehouseShipmentActs?marketPlaceID=${MpID}&mpWarehouseID=${warehouseID}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Акт отгрузки подтвержден");
      } else {
        toast.error("Повторите попытку позже");
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      toast.error("Повторите попытку позже");
    }
  };

  setOrderProcess = async (
    orderId: number,
    orderProcess: boolean
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_ORDER");
      const response = await fetch(
        `${endpoint}/SetOrderProcess?OrderID=${orderId}&OrderProcess=${orderProcess}`,
        requestOptions
      );

      if (response.ok) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
}

export default OrderStore;
